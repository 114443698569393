import React from 'react';
import TextTransition, { presets } from 'react-text-transition';

export default function AnimatedText({
  texts,
  delay = 3000,
  infinite = true,
  className,
}) {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    if (infinite || (!infinite && index < texts.length)) {
      const intervalId = setInterval(() => {
        if (index < texts.length - 1) {
          setIndex((actualIndex) => actualIndex + 1);
        } else {
          setIndex((_) => 0);
        }
      }, delay);
      return () => clearInterval(intervalId);
    }
  });

  return (
    <TextTransition
      className={className}
      springConfig={presets.wobbly}
    >
      {texts[index]}
    </TextTransition>
  );
}
