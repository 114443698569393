
import StudyIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';
import styles from './Timelapse.module.scss';

export default function Timelapse() {
  return (
    <div className={styles.root}>
      <VerticalTimeline className={styles.Timelapse}>
      <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2023 - present'
          icon={<WorkIcon fontSize='large' />}>
          <h3 className={styles.title}>Flutter developer</h3>
          <h4 className={styles.subtitle}>Singapour, Truely</h4>
          <p>Leading mobile app development</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2022 - 2023'
          icon={<WorkIcon fontSize='large' />}>
          <h3 className={styles.title}>Flutter developer</h3>
          <h4 className={styles.subtitle}>Moscow, TheKey</h4>
          <p>Participation in the development of a mobile application for flexible offices on Flutter.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2021 - 2022'
          icon={<WorkIcon fontSize='large' />}>
          <h3 className={styles.title}>Flutter developer</h3>
          <h4 className={styles.subtitle}>Moscow, Voxter</h4>
          <p>Participation in the development of a dating mobile application on Flutter.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2019 - 2021'
          icon={<WorkIcon fontSize='large' />}>
          <h3 className={styles.title}>Flutter developer</h3>
          <h4 className={styles.subtitle}>Moscow, RBC</h4>
          <p>Leading developer of a progressive B2B mobile application for the provision of electronic periodicals.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='Spring 2019'
          icon={<StudyIcon />}>
          <h3 className={styles.title}>Development courses</h3>
          <h4 className={styles.subtitle}>Saint Petersburg, HolyJS 2019</h4>
          <p>About 1,000 JS developers gathered under one roof to discuss the news of the rapidly growing ecosystem, fresh tools, frameworks, patterns and hundreds of changes with experts from around the world and hear dozens of reports about the frontend and not only.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2017 - 2019'
          icon={<WorkIcon />}>
          <h3 className={styles.title}>Senior frontend developer</h3>
          <h4 className={styles.subtitle}>Tyumen, Creative</h4>
          <p>Professional website development on order, outstaff work in large companies. Work on NDA projects.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={styles['Timelapse-element']}
          iconClassName={styles['vertical-timeline-element-icon']}
          textClassName={styles['Timelapse-element__content']}
          dateClassName={styles['Timelapse-element__date']}
          date='2014 - 2018'
          icon={<StudyIcon />}>
          <h3 className={styles.title}>Computer Systems Technician</h3>
          <h4 className={styles.subtitle}>Tyumen, TIU</h4>
          <p>The study of electrical engineering design methods, networking and basic knowledge in many industries</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}
