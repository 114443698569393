import React from 'react';
import classNames from 'classnames';

import styles from './Header.module.scss'

export default function Header({
  dense,
  children,
  className,
}) {
  const [getIsScrolled, setIsScrolled] = React.useState(false);
  React.useEffect(() => {
    function scrollListener(event) {
      if (window.scrollY < 100) {
        setIsScrolled(false)

      } else {
        setIsScrolled(true)
      }
    }

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener);
    }
  })

  return (
    <header className={classNames(styles.Header, {
      [styles['Header__dense']]: getIsScrolled,
      [styles['Header__scrolled']]: getIsScrolled,
    }, className)}>
      <nav className={styles['Header__navigation']}>
        {children}
      </nav>
    </header>
  )
}

export function HeaderItem({
  children,
  className,
  onClick,
  selected = false,
}) {
  return (
    <div onClick={onClick} className={classNames(styles.HeaderItem, {
      [styles['HeaderItem__selected']]: selected,
    }, className)} role="button">
      {children}
    </div>
  );
}
