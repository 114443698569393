

import Downward from 'assets/downward.png';
import AnimatedText from 'components/AnimatedText';
import style from './Main.module.scss';

export default function Main() {
  return (
    <div className={style.Main}>
      <div className={style['Main-photo-wrapper']}>
        <img className={style['Main-photo']} src={'/photo.jpeg'} alt="me" />
        {/* <img className={style['Main-photo']} src={'https://www.gravatar.com/avatar/eb867cf26c23a23a5a981e0a4e193d68?s=200'} alt="me" /> */}
      </div>
      <h1 className={style['Main-title']}>Hi! i'm Serge</h1>
      <div className={style['Main-subtitle']}>
        and I am a&nbsp;
        <AnimatedText className={style['Main-subtitle__live-text']} delay={2500} texts={[
          'Flutter',
          'Frontend',
          'Fullstack',
        ]} />
        &nbsp;developer
      </div>
      <img
        className={style['Main-downward']}
        alt='Downward'
        src={Downward}
        width='40'
        onClick={() => window.scrollTo({ top: window.screen.height * 0.75, left: 0, behavior: 'smooth' })} />
    </div>
  );
}
