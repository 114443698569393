import React from 'react';

import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from 'react-scroll-section';
import BackgroundAnimation from 'components/BackgroundAnimation';
import Header, { HeaderItem } from 'components/Header';
import Main from './Main';
import Timelapse from './Timelapse';
import Contacts from './Contacts';
import Skills from './Skills';

import styles from './App.module.scss'

// import ReactGA from 'react-ga';
// ReactGA.initialize('G-SLNZ587LVC');
// ReactGA.pageview(window.location.pathname + window.location.search);

const menuLocalization = {
  'timelapse': 'Timelapse',
  'skills': 'Skills',
  'contacts': 'Contacts',
}

function AppHeader() {
  const sections = useScrollSections();

  return (
    <Header className={styles.Header} dense>
      {sections.map(({ id, onClick, selected }) => menuLocalization[id] ? (
        <HeaderItem key={id} onClick={onClick} selected={selected}>
          {menuLocalization[id]}
        </HeaderItem>
      ) : null)}
    </Header>
  );
}

function App() {

  return (
    <BackgroundAnimation>
      <ScrollingProvider>
        <AppHeader />
        <Section id='main'>
          <Main />
        </Section>
        <Section id='timelapse'>
          <Timelapse />
        </Section>
        <Section id='skills'>
          <Skills />
        </Section>
        <Section id='contacts'>
          <Contacts />
        </Section>
      </ScrollingProvider>
    </BackgroundAnimation>
  );
}

export default App;
