import React from 'react';
import styles from './Contacts.module.scss'
import ga4react from 'utils/analytics'
// import ReactGA from 'react-ga';


const social = [
  { title: 'Telegram', link: 'https://t.me/Serge_Shkurko' },
  { title: 'GitHub', link: 'https://github.com/SergeShkurko' },
  { title: 'VK', link: 'https://vk.com/id218550769' },
  { title: 'Facebook', link: 'https://www.facebook.com/serge.shkurko' },
  { title: 'Twitter', link: 'https://twitter.com/serge_shkurko' },
  { title: 'Linkedin', link: 'https://linkedin.com/in/serge-shkurko-9629b7170' },
  { title: 'Instagram', link: 'https://www.instagram.com/serge_shkurko' },
  { title: 'E-Mail', link: 'mailto:sergeshkurko@outlook.com' },
];

export default function Contacts() {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Contacts</h3>
      <div className={styles.Contacts}>

        {social.map((item, index) => <a
          key={index}
          target='_blank'
          rel='noopener noreferrer'
          className={styles['Contacts-link']}
          onClick={() => {
            // ga4react.event({
            //   category: 'Contacts',
            //   action: 'Open contact',
            //   value: item.value,
            // });
            ga4react.event({
              category: 'Contacts',
              action: 'Open contact',
              label: item.value,
            });
          }}
          href={item.link}>{item.title}</a>)}
      </div>
    </div>
  );
}
