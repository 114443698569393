import GA4React from 'ga-4-react';

const ga4react = new GA4React(
  'G-SLNZ587LVC',
  { /* ga custom config, optional */ },
  [ /* additional code, optional */],
  5000 /* timeout, optional, defaults is 5000 */,

);

ga4react.initialize().then((ga4) => {
  ga4.pageview('path')
  // ga4.gtag('event','pageview','path') // or your custom gtag event
}, (err) => {
  console.error(err)
})

export default ga4react;
