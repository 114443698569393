import React from 'react';

import Chip from 'components/Chip';

import 'react-vertical-timeline-component/style.min.css';
import styles from './Skills.module.scss'

const skills = [{
  name: 'Flutter',
}, {
  name: 'Dart',
}, {
  name: 'React',
}, {
  name: 'Pug',
}, {
  name: 'Sass',
}, {
  name: 'Stylus',
}, {
  name: 'Gulp',
}, {
  name: 'Grunt',
}, {
  name: 'Webpack',
}, {
  name: 'Node.js',
}, {
  name: 'Electron.js',
}, {
  name: 'nw.js',
}, {
  name: 'Polymer',
}, {
  name: 'React Native',
}, {
  name: 'Babel',
}, {
  name: 'TypeScript',
}, {
  name: 'ES6',
}, {
  name: 'Cordova',
}, {
  name: 'Phonegap',
}, {
  name: 'Kotlin',
}, {
  name: 'WebSocket',
}, {
  name: 'Ajax',
}, {
  name: 'JSON API',
}, {
  name: 'HTML5',
}, {
  name: 'CSS3',
}, {
  name: 'PHP8',
}, {
  name: 'Symfony',
}, {
  name: 'Python',
}, {
  name: 'Django',
}, {
  name: 'Django rest framework',
}, {
  name: 'Docker',
}];

export default function Timelapse() {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Skills</h3>
      <div className={styles.container}>
        {skills.map((skill, index) => <Chip className={styles.chip} label={skill.name} key={index} />)}
      </div>
    </div>
  );
}
