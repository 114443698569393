import React from 'react';
import * as noise from 'utils/noise';
import './BackgroundAnimation.scss'

export default class BackgroundAnimation extends React.Component {
  canvasRef = React.createRef();

  controls = {
    particles: 250,
    opacity: 1,
    size: 0.85,
    trail: 0.25,
    colors: 200,
    noise: 1,
    velocity: 0.25,
    lines: 50,
    vectors: 50
  };

  ctx;
  canvas;
  particles = [];
  field;
  noiseZ = 0;
  PI2 = Math.PI * 2;

  isLight = false;

  width;
  height;

  componentDidMount() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.canvas = this.canvasRef.current;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.ctx = this.canvas.getContext('2d');

    this.isLight = false;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      this.isLight = true;
    }

    this.resize();
    this.tick();

    window.addEventListener('resize', this.resize, false);
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', this.themeListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    cancelAnimationFrame(this.tick);
    window.matchMedia('(prefers-color-scheme: light)').removeEventListener('change', this.themeListener);
  }

  themeListener = (event) => {
    this.isLight = event.matches;
    this.resize();
  }

  resize = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.canvas.width = this.width;
    this.canvas.height = this.height;

    if (this.width <= 576) {
      this.controls.particles = 250;
    } else if (this.width <= 768) {
      this.controls.particles = 500;
    } else if (this.width <= 1280) {
      this.controls.particles = 1000;
    } else if (this.width <= 1920) {
      this.controls.particles = 1500;
    } else {
      this.controls.particles = 2000;
    }



    if (this.isLight) {
      this.ctx.fillStyle = '#fff';
    } else {
      this.ctx.fillStyle = '#000';
    }
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.lineWidth = 2;

    this.particles = [];
    this.populate();

    this.change();
  }

  change = () => {
    for (let i = 0; i < this.particles.length; i++) {
      if (this.isLight) {
        this.particles[i].c = `hsla(${Math.round(Math.random() * this.controls.colors)}, 0%, 0%, ${Math.random() * this.controls.opacity})`;
      } else {
        this.particles[i].c = `hsla(${Math.round(Math.random() * this.controls.colors)}, 100%, 50%, ${Math.random() * this.controls.opacity})`;
      }
    }

    // this.controls.fade = `rgba(18,18,18,${(1 - this.controls.trail) * 0.3})`;
    this.controls.lineDistance = this.controls.lines * this.controls.lines;
    if (this.isLight) {
      this.controls.fade = `rgba(250,250,250,1)`;
      this.ctx.strokeStyle = `hsla(${this.controls.colors},0%,0%,${(110 - this.controls.lines) * 0.0005})`;
    } else {
      this.controls.fade = `rgba(18,18,18,1)`;
      this.ctx.strokeStyle = `hsla(${this.controls.colors},100%,50%,${(110 - this.controls.lines) * 0.0005})`;
    }

    this.field = new Array(this.controls.vectors);
    for (let x = 0; x < this.controls.vectors; x++) {
      this.field[x] = new Array(this.controls.vectors);
    }
  }

  populate = () => {
    for (let i = this.particles.length; i < this.controls.particles; i++) {
      this.particles.push({
        x: Math.random() * this.width,
        y: Math.random() * this.height,
        v: (0.1 + Math.random() * 0.9)
      });
    }

    this.change();
  }

  tick = () => {
    requestAnimationFrame(this.tick);

    this.ctx.fillStyle = this.controls.fade;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.noiseZ += this.controls.noise * 0.01;
    if (this.noiseZ > 75) {
      this.noiseZ = 0;
    }
    let x, y, a;
    for (x = 0; x < this.controls.vectors; x++) {
      for (y = 0; y < this.controls.vectors; y++) {
        a = noise.simplex3(x / this.controls.vectors, y / this.controls.vectors, this.noiseZ) * this.PI2;
        this.field[x][y] = {
          x: Math.sin(a),
          y: Math.cos(a)
        };
      }
    }

    let i, p, v;
    for (i = 0; i < this.controls.particles; i++) {
      p = this.particles[i];
      if (p == null) {
        return;
      }

      x = Math.floor(p.x / this.canvas.width * this.controls.vectors);
      y = Math.floor(p.y / this.canvas.height * this.controls.vectors);
      v = this.field[x][y];

      p.x = Math.max(1, Math.min(this.canvas.width - 1, p.x + v.x * p.v * this.controls.velocity));
      p.y = Math.max(1, Math.min(this.canvas.height - 1, p.y + v.y * p.v * this.controls.velocity));
    }

    if (this.controls.lines) {
      this.ctx.beginPath();

      let j, p1, p2, dx, dy;
      for (i = 0; i < this.controls.particles; i++) {
        p1 = this.particles[i];
        for (j = i + 1; j < this.controls.particles; j++) {
          p2 = this.particles[j];
          dx = p2.x - p1.x;
          dy = p2.y - p1.y;

          if (dx * dx + dy * dy <= this.controls.lineDistance) {
            this.ctx.moveTo(p1.x, p1.y);
            this.ctx.lineTo(p2.x, p2.y);
          }
        }
      }

      this.ctx.stroke();
    }

    if (this.controls.size) {
      let h = this.controls.size / 2;
      for (i = 0; i < this.controls.particles; i++) {
        p = this.particles[i];
        this.ctx.fillStyle = p.c;
        this.ctx.fillRect(p.x - h, p.y - h, this.controls.size, this.controls.size);
      }
    }
  }

  render() {
    return (
      <div className='BackgroundAnimation'>
        <canvas ref={this.canvasRef} />
        <div className='BackgroundAnimation__children'>{this.props.children}</div>
      </div>
    );
  }
}
