import classNames from 'classnames';
import React from 'react';

import './Chip.scss'

export default function Chip({
  label,
  className,
}) {
  return (
    <div className={classNames('Chip', className)}>
      {label}
    </div>
  )
}
